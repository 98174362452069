<template>
  <div
    class="box-line d-flex justify-content-between align-items-center"
    :class="{ 'border-bottom-dark mb-1': isHeader }"
  >
    <div class="type">
      <p v-if="isHeader">
        TIPO
      </p>
      <p v-else>
        <e-instructions
          v-if="instruction"
          :instruction="instruction"
        />

        {{ title }}
      </p>
    </div>

    <div
      class="d-flex align-items-center"
      style="gap: 10px"
    >
      <div
        v-if="showEstimatedValue"
        class="estimated-value text-right"
      >
        <p v-if="isHeader">
          VALOR ESTIMADO
        </p>
        <p v-else>
          {{ estimatedValue | currency }}
        </p>
      </div>

      <div class="input-value text-right">
        <p v-if="isHeader">
          VALOR
        </p>
        <slot v-else />
      </div>
    </div>
  </div>
</template>

<script>
import { EInstructions } from '@/views/components'

export default {
  components: { EInstructions },
  props: {
    title: {
      type: String,
      default: '',
    },
    instruction: {
      type: String,
      default: '',
    },
    estimatedValue: {
      type: Number,
      default: 0,
    },
    showEstimatedValue: {
      type: Boolean,
      default: false,
    },
    isHeader: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.type {
  width: 200px;
}

.box-line:focus-within {
  color: var(--theme-color);
}

.estimated-value {
  width: 125px;
}

.input-value {
  width: 125px;
}
</style>
