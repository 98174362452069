var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "container-close" },
    [
      _c(
        "b-container",
        [
          _c(
            "FormulateForm",
            {
              attrs: { name: "formPayBoxClose" },
              on: { submit: _vm.onSubmitClose },
            },
            [
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { md: "12" } }, [
                    _c("p", { staticClass: "h1 text-primary my-1" }, [
                      _vm._v(" Fechamento do caixa "),
                    ]),
                  ]),
                  _c("b-col", { attrs: { md: "12" } }, [
                    _c("p", { staticClass: "h3" }, [
                      _vm._v(" Resumo do Caixa "),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c(
                        "b-card-actions",
                        {
                          attrs: { "no-actions": "" },
                          scopedSlots: _vm._u([
                            {
                              key: "title",
                              fn: function () {
                                return [
                                  _c(
                                    "p",
                                    { staticClass: "h4 mb-0 text-info" },
                                    [_vm._v(" Total das Entradas ")]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _c("b-col", { attrs: { md: "12" } }, [
                            _c(
                              "div",
                              { staticClass: "container-box" },
                              [
                                _c("box-line", {
                                  attrs: {
                                    "is-header": "",
                                    "show-estimated-value":
                                      _vm.showEstimatedValues,
                                  },
                                }),
                                _vm._l(
                                  _vm.localSummaryCreditViewPermission,
                                  function (credit) {
                                    return _c(
                                      "box-line",
                                      {
                                        key:
                                          credit.origin +
                                          "_" +
                                          credit.paymentMethod.id,
                                        attrs: {
                                          title: _vm.getCreditLabel(credit),
                                          "estimated-value": credit.total,
                                          "show-estimated-value":
                                            _vm.showEstimatedValues,
                                        },
                                      },
                                      [
                                        credit.readOnly || credit.online
                                          ? _c("FormulateInput", {
                                              attrs: {
                                                id:
                                                  "input-credit-" +
                                                  credit.origin +
                                                  "_" +
                                                  credit.paymentMethod.id,
                                                type: "label",
                                                "input-class": "text-right",
                                                filter: "currency",
                                              },
                                              model: {
                                                value: credit.totalTyped,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    credit,
                                                    "totalTyped",
                                                    $$v
                                                  )
                                                },
                                                expression: "credit.totalTyped",
                                              },
                                            })
                                          : _c("FormulateInput", {
                                              attrs: {
                                                id:
                                                  "input-credit-" +
                                                  credit.origin +
                                                  "_" +
                                                  credit.paymentMethod.id,
                                                type: "text-number",
                                                "input-class": "text-right",
                                                currency: "R$",
                                                precision: 2,
                                              },
                                              on: {
                                                input: _vm.onInputTotalsCredit,
                                              },
                                              model: {
                                                value: credit.totalTyped,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    credit,
                                                    "totalTyped",
                                                    $$v
                                                  )
                                                },
                                                expression: "credit.totalTyped",
                                              },
                                            }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                _c(
                                  "box-line",
                                  {
                                    staticClass: "font-weight-bold",
                                    attrs: {
                                      title: "Total",
                                      "show-estimated-value":
                                        _vm.showEstimatedValues,
                                      "estimated-value":
                                        _vm.getTotalsEstimated.credit,
                                    },
                                  },
                                  [
                                    _c("p", { staticClass: "h4 text-dark" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("currency")(
                                              _vm.totalsTypedCreditViewPermission
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm.showEstimatedValues
                                  ? _c(
                                      "box-line",
                                      {
                                        staticClass: "font-weight-bold",
                                        attrs: {
                                          title: "Total [Em dinheiro]",
                                          "show-estimated-value":
                                            _vm.showEstimatedValues,
                                          "estimated-value":
                                            _vm.getTotalsEstimated.creditCash,
                                        },
                                      },
                                      [
                                        _c(
                                          "p",
                                          { staticClass: "h4 text-dark" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    _vm.totalsTypedCreditCashViewPermission
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c(
                        "b-card-actions",
                        {
                          attrs: { "no-actions": "" },
                          scopedSlots: _vm._u([
                            {
                              key: "title",
                              fn: function () {
                                return [
                                  _c(
                                    "p",
                                    { staticClass: "h4 mb-0 text-danger" },
                                    [_vm._v(" Total das Saídas ")]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _c("b-col", { attrs: { md: "12" } }, [
                            _c(
                              "div",
                              { staticClass: "container-box" },
                              [
                                _c("box-line", {
                                  attrs: {
                                    "is-header": "",
                                    "show-estimated-value":
                                      _vm.showEstimatedValues,
                                  },
                                }),
                                _vm._l(_vm.localSummaryDebit, function (debit) {
                                  return _c(
                                    "box-line",
                                    {
                                      key:
                                        debit.origin +
                                        "_" +
                                        debit.paymentMethod.id,
                                      attrs: {
                                        title:
                                          _vm.saleOriginEnumLabel[
                                            debit.origin
                                          ] +
                                          " [" +
                                          debit.paymentMethod.name +
                                          "]",
                                        "estimated-value": debit.total,
                                        "show-estimated-value":
                                          _vm.showEstimatedValues,
                                      },
                                    },
                                    [
                                      _c("FormulateInput", {
                                        attrs: {
                                          id:
                                            "input-debit-" +
                                            debit.origin +
                                            "_" +
                                            debit.paymentMethod.id,
                                          type: "text-number",
                                          "input-class": "text-right",
                                          currency: "R$",
                                          precision: 2,
                                        },
                                        on: { input: _vm.onInputTotalsDebit },
                                        model: {
                                          value: debit.totalTyped,
                                          callback: function ($$v) {
                                            _vm.$set(debit, "totalTyped", $$v)
                                          },
                                          expression: "debit.totalTyped",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }),
                                _c(
                                  "box-line",
                                  {
                                    staticClass: "font-weight-bold",
                                    attrs: {
                                      title: "Total",
                                      "show-estimated-value":
                                        _vm.showEstimatedValues,
                                      "estimated-value":
                                        _vm.getTotalsEstimated.debit,
                                    },
                                  },
                                  [
                                    _c("p", { staticClass: "h4 text-dark" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("currency")(
                                              _vm.totalsTyped.debit
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "box-line",
                                  {
                                    staticClass: "font-weight-bold",
                                    attrs: {
                                      title: "Total [Em dinheiro]",
                                      "show-estimated-value":
                                        _vm.showEstimatedValues,
                                      "estimated-value":
                                        _vm.getTotalsEstimated.debitCash,
                                    },
                                  },
                                  [
                                    _c("p", { staticClass: "h4 text-dark" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("currency")(
                                              _vm.totalsTyped.debitCash
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              2
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-card-actions",
                        {
                          attrs: { "no-actions": "" },
                          scopedSlots: _vm._u([
                            {
                              key: "title",
                              fn: function () {
                                return [
                                  _c(
                                    "p",
                                    { staticClass: "h4 mb-0 text-primary" },
                                    [_vm._v(" Saldo Caixa [Em Dinheiro] ")]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _c("b-col", { attrs: { md: "12" } }, [
                            _c(
                              "div",
                              { staticClass: "container-box" },
                              [
                                _c("box-line", {
                                  attrs: {
                                    "is-header": "",
                                    "show-estimated-value":
                                      _vm.showEstimatedValues,
                                  },
                                }),
                                _c(
                                  "box-line",
                                  {
                                    attrs: {
                                      title: _vm.$t("Troco inicial caixa"),
                                    },
                                  },
                                  [
                                    _c("FormulateInput", {
                                      attrs: {
                                        id: "pay_box_close-opening_balance",
                                        name: "openingBalance",
                                        filter: "currency",
                                        type: "label",
                                        "input-class": "text-right",
                                      },
                                      model: {
                                        value: _vm.openingBalance,
                                        callback: function ($$v) {
                                          _vm.openingBalance = $$v
                                        },
                                        expression: "openingBalance",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "box-line",
                                  {
                                    attrs: {
                                      title: _vm.$t("Troco final caixa"),
                                      "show-estimated-value":
                                        _vm.showEstimatedValues,
                                      "estimated-value":
                                        _vm.getTotalsEstimated
                                          .finalPayBoxChange,
                                    },
                                  },
                                  [
                                    _c("FormulateInput", {
                                      attrs: {
                                        id: "input_totals-credit_final_pay_box_change",
                                        name: "finalPayBoxChange",
                                        type: "text-number",
                                        "input-class": "text-right",
                                        currency: "R$",
                                        precision: 2,
                                      },
                                      on: { input: _vm.onInpuTotals },
                                      model: {
                                        value: _vm.finalPayBoxChange,
                                        callback: function ($$v) {
                                          _vm.finalPayBoxChange = $$v
                                        },
                                        expression: "finalPayBoxChange",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "box-line",
                                  {
                                    attrs: {
                                      title: _vm.$t("Sangria Final"),
                                      "show-estimated-value":
                                        _vm.showEstimatedValues,
                                      "estimated-value":
                                        _vm.getTotalsEstimated
                                          .finalBleedingValue,
                                    },
                                  },
                                  [
                                    _c("FormulateInput", {
                                      attrs: {
                                        id: "input_totals-debit_final_bleeding",
                                        name: "finalBleeding",
                                        type: "text-number",
                                        "input-class": "text-right",
                                        currency: "R$",
                                        precision: 2,
                                      },
                                      on: { input: _vm.onInpuTotals },
                                      model: {
                                        value: _vm.finalBleeding,
                                        callback: function ($$v) {
                                          _vm.finalBleeding = $$v
                                        },
                                        expression: "finalBleeding",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm.showEstimatedValues
                                  ? _c(
                                      "box-line",
                                      {
                                        attrs: {
                                          title: "Saldo final caixa",
                                          "show-estimated-value":
                                            _vm.showEstimatedValues,
                                          "estimated-value":
                                            _vm.getTotalsEstimated
                                              .cashTotalBalance,
                                          instruction: _vm.$t(
                                            "CASH_BOOK.CLOSE.UI.FINAL_BALANCE"
                                          ),
                                        },
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            class: {
                                              h4: true,
                                              "text-dark":
                                                _vm.cashTotalBalance === 0,
                                              "text-success":
                                                _vm.cashTotalBalance < 0,
                                              "text-danger":
                                                _vm.cashTotalBalance > 0,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    _vm.cashTotalBalance
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.isForcedClose
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-card-actions",
                            {
                              attrs: {
                                title: _vm.$t("Outras informações"),
                                "no-actions": "",
                              },
                            },
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c(
                                    "b-alert",
                                    {
                                      staticClass: "p-2",
                                      attrs: { show: "", variant: "danger" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(_vm.divergenceMessage)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c("FormulateInput", {
                                    staticClass: "required",
                                    attrs: {
                                      id: "pay_box_close-observation",
                                      name: "observation",
                                      type: "textarea",
                                      label: _vm.$t("Observação"),
                                      validation: "required|min:10",
                                    },
                                    model: {
                                      value: _vm.forcedForm.observation,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.forcedForm,
                                          "observation",
                                          $$v
                                        )
                                      },
                                      expression: "forcedForm.observation",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "d-flex justify-content-end align-items-center",
                                  attrs: { md: "12" },
                                },
                                [
                                  _c("e-button", {
                                    attrs: { text: _vm.$t("Ver extrato") },
                                    on: { click: _vm.showStatementSidebar },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-row",
                { staticClass: "pb-2" },
                [
                  _c(
                    "b-col",
                    [
                      _c("e-button", {
                        attrs: { text: _vm.$t("Voltar"), block: "" },
                        on: {
                          click: function () {
                            return _vm.$router.back()
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c("e-button", {
                        attrs: {
                          type: "submit",
                          variant: "primary",
                          text: _vm.$t("Confirmar Fechamento"),
                          block: "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isForcedClose
        ? _c("statement-sidebar", { ref: "statementSidebar" })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }