<template>
  <div>
    <e-sidebar
      :title="$t('Extrato do caixa')"
      :show="showSidebar"
      hide-save-button
      :fetching="fetching"
      width="900px"
      @hidden="hideSidebar"
    >
      <template #content>
        <pay-box-statement style="margin: 0 !important" />
      </template>
    </e-sidebar>
  </div>
</template>

<script>
import { ESidebar } from '@/views/components'
import { alerts } from '@/mixins'
import PayBoxStatement from './PayBoxStatement.vue'

export default {
  components: {
    ESidebar,
    PayBoxStatement,
  },

  mixins: [alerts],

  props: {},

  data() {
    return {
      showSidebar: false,
      fetching: false,
    }
  },

  methods: {
    show() {
      this.showSidebar = true
    },

    hideSidebar() {
      this.showSidebar = false
    },
  },
}
</script>

<style></style>
