var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("e-sidebar", {
        attrs: {
          title: _vm.$t("Extrato do caixa"),
          show: _vm.showSidebar,
          "hide-save-button": "",
          fetching: _vm.fetching,
          width: "900px",
        },
        on: { hidden: _vm.hideSidebar },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("pay-box-statement", {
                  staticStyle: { margin: "0 !important" },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }