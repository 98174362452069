var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "box-line d-flex justify-content-between align-items-center",
      class: { "border-bottom-dark mb-1": _vm.isHeader },
    },
    [
      _c("div", { staticClass: "type" }, [
        _vm.isHeader
          ? _c("p", [_vm._v(" TIPO ")])
          : _c(
              "p",
              [
                _vm.instruction
                  ? _c("e-instructions", {
                      attrs: { instruction: _vm.instruction },
                    })
                  : _vm._e(),
                _vm._v(" " + _vm._s(_vm.title) + " "),
              ],
              1
            ),
      ]),
      _c(
        "div",
        {
          staticClass: "d-flex align-items-center",
          staticStyle: { gap: "10px" },
        },
        [
          _vm.showEstimatedValue
            ? _c("div", { staticClass: "estimated-value text-right" }, [
                _vm.isHeader
                  ? _c("p", [_vm._v(" VALOR ESTIMADO ")])
                  : _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("currency")(_vm.estimatedValue)) +
                          " "
                      ),
                    ]),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "input-value text-right" },
            [_vm.isHeader ? _c("p", [_vm._v(" VALOR ")]) : _vm._t("default")],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }